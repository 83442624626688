#edit-file {
    .card-header {
        .title {
            font-size: 1.5em;
        }
    }
    .card-body {
        .badge {
            font-size: 1em;
            margin-bottom: 20px;
        }
        .processing,
        .transcoding {
            color: orange;
        }
        .failed {
            color: red;
        }
    }
}
