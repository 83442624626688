.login-forms {
    margin-top: 40px;

    .card {
        margin-bottom: 40px;

        .card-body {
            .text-secondary {
                margin-bottom: 30px;
            }
        }
    }
}
