#states {
    .list-group-item {
        padding: 0.50rem 0.50rem !important;
    }

    .fa-bars {
        cursor: grab;
    }

    .fa-grip-lines {
        color: #999999;
    }
}
