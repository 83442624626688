.navbar {
    background-color: $niagara !important;

    .navbar-brand .nav-link {
        color: white;
        font-weight: bold;
        border: 1px solid;
        padding: 0.5rem 1rem;
    }

    .navbar-brand {
        font-size: 2rem;
    }

    .navbar-toggler {
        border-color: rgba(255, 255, 255, 0.6) !important;
    }

    .nav-item {
        font-size: 1rem;

        .nav-link {
            color: white;
        }

        .breadcrumbs {
            color: white;

            a {
                color: white;
            }
        }
    }
}
