// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// --------------
// Vendors stylesheets
// --------------

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// Font Awesome
@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/scss/regular';
@import '~@fortawesome/fontawesome-free/scss/solid';

// Uppy
@import '~@uppy/core/dist/style.css';
@import '~@uppy/dashboard/dist/style.css';

// --------------
// App stylesheets
// --------------

// Base
@import 'base';

// Legacy styles
@import 'legacy';

// Navbar
@import 'header';

// Admin views
@import 'admin';

// Login views
@import 'login';

// Invitation views
@import 'invitations';

// User views
@import 'users';

// Courses views
@import 'courses';

// Files views
@import 'files';

// Cards views
@import 'cards';

// States views
@import 'states';

// Finder views
@import 'finder';
