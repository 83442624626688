#index-courses {
    .flex-table {
        display: flex;
        flex-direction: column;

        .flex-row {
            display: flex;
            padding: 10px;
            column-gap: 10px;
            row-gap: 2px;
            border-bottom: 1px solid #ddd;
            align-items: flex-start;
        }

        .flex-row:nth-child(even) {
            background-color: #f2f2f2;
        }

        .flex-row.header {
            font-weight: bold;
        }

        .name {
            flex: 2.5;

            a {
                text-decoration: none;
                font-weight: bold;
            }
        }

        .managers {
            flex: 1;
        }

        .date {
            flex: 0.6;
        }

        .description {
            display: -webkit-box;
            text-overflow: ellipsis;
            overflow: hidden;
            -webkit-box-orient: vertical;
            flex: 100%;
            position: relative;

            &:hover {
                .reduce-button,
                .expand-button {
                    i {
                        @extend .fa-solid;
                    }
                }
            }

            .reduce-button,
            .expand-button {
                display: none;
                position: absolute;
                bottom: 0;
                right: 0;
            }

            &.expandable {
                cursor: pointer;

                .expand-button {
                    display: none;
                }

                .reduce-button {
                    display: block;
                }

                &.line-clamp-2 {
                    .expand-button {
                        display: block;
                    }

                    .reduce-button {
                        display: none;
                    }
                }
            }

        }

        .line-clamp-2 {
            -webkit-line-clamp: 2;
        }

        .deleted {
            .name a {
                text-decoration: line-through;
                color: rgba(#f00, .6);
                font-weight: normal;
            }

            .managers, .date, .description {
                color: rgba(#000, .3);
            }
        }
    }

    .d-box {
        display: -webkit-box!important;
    }
}

#edit-course {
    .card-header {
        .title {
            font-size: 1.5em;
        }
    }
    .card-body {
        .badge {
            font-size: 1em;
            margin-bottom: 20px;
        }
    }
}

@include media-breakpoint-up(md) {
    #index-courses {
        .flex-table {
            .description {
                flex: 3;
                display: -webkit-box!important;
            }
        }
    }
}

.description-tooltip {
    --bs-tooltip-max-width: 500px;
}
