#card {
    .card-header {
        #edit-failed-box2,
        #edit-failed-box3,
        #edit-failed-box4 {
            color: red;
        }
    }

    .box2,
    .box3,
    .box4 {
        .card-body {
            padding-right: 0.5rem !important;
            padding-left: 0.5rem !important;
        }

        .card-body:has([id^="rct-editor"]) {
            padding: calc(var(--bs-card-spacer-y) + 0.2rem) var(--bs-card-spacer-x);
        }

        [id^="rct-editor"].editing {
            border: 1px solid rgba(215, 215, 215, 0.35);
        }

        .fixed-height {
            max-height: 442px;
            overflow-y: scroll;
        }
    }

    .box1 {
        .youtube-container {
            position: relative;
            width: 100%;
            padding-bottom: 56.25%; /* 16:9 ratio (height/width = 9/16 = 0.5625) */
            height: 0;
            overflow: hidden;

            iframe {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                border: 0;
            }
        }

        .media-status {
            .progress {
                height: 20px;
                width: 50%;
                margin: auto;
            }
        }
    }

    .box2 {
        .card-body {
            height: 31.9em;
            overflow-y: scroll;
        }

        // Make the card body resizable
        #transcription-viewer {
            resize: vertical;
            overflow: auto;
        }

        #transcription-content {
            display: inline-block;
        }

        .transcription-row {
            padding: 0 10px;

            .speech, .speaker > input {
                background: transparent;
                border-width: 0;
                box-sizing: content-box;
                margin: 0;
                outline: none;
                overflow: hidden;
                padding: 0;
                resize: none;
                color: black!important;
            }

            .speaker > input {
                width: 100%;
            }

            &:has(.transcription-actions .action-delete:hover) {
                .line-number, .speaker, .speech {
                    background-color: transparent;
                }
                background-image: repeating-linear-gradient(45deg, #ff6f6f 0, #ff6f6f 0.5px, transparent 0, transparent 50%);
                background-color: #ffc8c8;
                background-size: 10px 10px;
            }

            &:has(.transcription-actions .action-toggle-number:hover) {
                .line-number:not(.no-line-number) {
                    text-decoration: line-through;
                    color: red;
                }

                .no-line-number {
                    opacity: .3;
                }
            }

            &:hover .transcription-actions {
                opacity: 1!important;

                span:hover {
                    cursor: pointer;

                    i {
                        @extend .fa-solid;
                    }
                }
            }
        }

        .editing {
            .speaker {
                background-color: #e3e3e3;

                > input {
                    background-color: transparent;
                }
            }

            .speech {
                background-color: #c3c3c3;
            }
        }
    }

    .box5 {
        .attachments-list {
            div {
                padding: 0.25rem;
            }

            div:nth-last-child(n+2) {
                border-bottom: 1px solid #dee2e6;
            }

            .attachment {
                padding-left: 0.5rem;
            }

            .attachment:hover {
                background-color: #f6f6f6;
            }
        }
    }
}

#configure-card {
    #rct-date-picker {
        .react-datepicker-wrapper {
            // Avoid width issue with date picker
            display: block;
        }
    }
}

#print-card {
    .break-page {
        break-after: page;
    }

    .transcription-row > td {
        padding: 0;
    }

    .speech {
        white-space: pre;
    }
}

#card, #print-card {
    .box2 {
        .transcription-row {
            font-size: 12px;
            line-height: 1.2;

            .line-number {
                width: 5ch;
            }

            .speaker {
                width: 6ch;
            }

            .no-line-number {
                opacity: 0;
            }
        }
    }

    .box2 .transcription-row, .font-transcription {
        font-family: courier, serif;
    }
}
