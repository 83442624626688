#users,
#invitations,
#courses,
#files {
    .card-header {
        .title {
            font-size: 1.5em;
        }
    }

    table {
        tbody {
            .badge {
                font-size: 1em;
            }

            .invalid {
                color: $invalid !important;
            }

            .actions {
                width: 10%;

                span {
                    display: inherit;
                    padding: 2px;
                }
            }
        }
    }
}

.admin-content {
    @media (max-width: 1000px) {
        #users,
        #invitations,
        #courses,
        #files {
            .card-header.d-flex {
                flex-direction: column;
            }
        }
    }
}
