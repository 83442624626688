
@keyframes pulsate {
    0% {
        transform: scale(1);
        opacity: .4;
    }
    50% {
        transform: scale(1.2);
        opacity: 1;
    }
    100% {
        transform: scale(1);
        opacity: .4;
    }
}

.icon-link {
    &.disabled {
        cursor: default;
        @extend .opacity-50;
    }

    i {
        transition: .2s ease-in-out transform;
    }
}

.icon-link-hover:hover:not(.disabled) {

    i {
        transform: translate3d(.25em, 0, 0);
    }

    i.icon-link-hover-left {
        transform: translate3d(-.25em, 0, 0);
    }
}

.pulse {
    animation: pulsate 1s infinite;
}

.navbar {
    margin-bottom: 20px;
}

.footer-content {
    margin-top: 30px;
}

.card {
    margin-bottom: 20px;
}

.admin-menu,
.course-configuration-menu {
    .nav {
        li.active {
            border: 1px dotted white;
        }

        .nav-item {
            a {
                color: white;
            }

            a:hover {
                color: black;
            }

            a.disabled {
                color: rgba(255, 255, 255, 0.40);
            }
        }
    }

    background-color: #05a69d;
    margin-top: -21px;
    padding: 3px 0;
    margin-bottom: 30px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.selected-bg {
    background-color: #80808014;
}

.text-niagara {
    @extend .text-primary;
    color: $niagara!important;
}

.dropdown-with-icon .dropdown-item > i {
    width: 25px;
    text-align: center;
}

.cursor-default {
    cursor: default;
}

.cursor-pointer {
    cursor: pointer;
}

.blue-500 {
    color: $blue-500;
}

.z-index-backdrop {
    z-index: $zindex-modal-backdrop;
}

.show-icon-on-hover {
    i[class*=" fa"] {
        display: none;
    }

    &:hover i[class*=" fa"] {
        display: inline;
    }
}

.table-responsive {
    td, th {
        @extend .text-nowrap;
        vertical-align: middle;
    }
}
